'use strict';

Gri.module({
  name: 'document-list-alpha',
  ieVersion: null,
  $el: $('.document-list-alpha'),
  container: '.document-list-alpha',
  fn: function () {
    
  }
});
